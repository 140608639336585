import React, { forwardRef, TouchEventHandler, useId } from "react";
import { mergeRefs } from "react-merge-refs";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { InputGroup, InputGroupProps } from "../../../../old/input-group";
import { Indicators } from "./indicators";
import { StepMarkers } from "./step-markers";
import { Thumb } from "./thumb";
import { Track } from "./track";

export type InputSliderIndicator = {
  label: string;
  value: number;
  icon?: string;
};

type InheritedProps = Pick<
  React.ComponentProps<"input">,
  "id" | "name" | "disabled"
>;

type InputSliderProps = {
  max: number;
  step: number;
  className?: string;
  indicators?: InputSliderIndicator[];
  min?: number;
  stepMarkers?: boolean;
  value?: number;
  valueChip?: boolean;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement> | null,
    newValue: number,
  ) => void;
  valueChipFormat?: (value: number) => string;
  validated?: boolean;
} & InheritedProps &
  InputGroupProps;

export const InputSlider = forwardRef<HTMLInputElement, InputSliderProps>(
  (props: InputSliderProps, ref) => {
    const {
      id,
      label,
      validated,
      required,
      errors,
      name,
      indicators,
      className,
      step,
      max,
      min = 0,
      value: valueProp = min,
      valueChip = true,
      disabled = false,
      stepMarkers = false,
      onChange,
      valueChipFormat,
      groupRef,
    } = props;

    const inputRef = React.useRef<HTMLInputElement>(null);
    let inputId = useId();
    inputId = id || inputId;

    const [value, setValue] = React.useState<number>(valueProp);

    React.useEffect(() => {
      if (valueProp > max) {
        return setValue(max);
      }
      if (valueProp < min) {
        return setValue(min);
      }

      setValue(valueProp);
    }, [max, min, valueProp]);

    const handleChange = (
      event: React.ChangeEvent<HTMLInputElement> | null,
      newValue = value,
    ) => {
      const valueToInt = event ? parseInt(event.target.value, 10) : newValue;
      setValue(valueToInt);
      onChange && onChange(event, valueToInt);
    };

    const getValuePercentage = (valueArg?: number): number => {
      const currentValue = valueArg ?? value;
      const range = max - min;

      return ((currentValue - min) / range) * 100;
    };

    const onDrag: TouchEventHandler<HTMLInputElement> = (e) => {
      if (inputRef.current) {
        const x = e.touches[0].clientX;
        const width = inputRef.current.clientWidth;
        const fromStart = Math.min(
          Math.max(0, x - inputRef.current.getBoundingClientRect().left),
          width,
        );
        const percent = (fromStart / width) * 100;
        const newValue = (percent / 100) * (max - min) + min;
        handleChange(null, parseInt(newValue.toFixed(0)));
      }
    };

    return (
      <InputGroup
        groupRef={groupRef}
        ref={mergeRefs([inputRef, ref])}
        id={inputId}
        className={className}
        fakeInputClassName="border-0 m-0 p-0 bg-inherit"
        disabled={disabled}
        label={label}
        required={required}
        errors={errors}
        hasValue={!!value}
        labelPosition="right"
        valid={validated}
        renderInput={(inputProps) => (
          <div
            className={cn("relative mt-[20px] w-full", {
              "pb-[50px]": !!indicators,
            })}
          >
            <div className="relative w-full">
              <input
                onTouchStart={onDrag}
                type="range"
                name={name}
                id={inputId}
                value={value ?? ""}
                step={step}
                min={min}
                max={max}
                disabled={disabled}
                onChange={handleChange}
                className={cn(
                  inputProps.className,
                  "cursor-pointer appearance-none",
                  "w-full",
                )}
                ref={inputProps.ref}
              />
              <Thumb
                disabled={disabled}
                value={value}
                displayChip={value > 0 && valueChip}
                valueChipFormat={valueChipFormat}
                percentageLeft={getValuePercentage()}
              />
              <Track
                disabled={disabled}
                percentageWidth={getValuePercentage()}
              />
              {stepMarkers && <StepMarkers max={max} step={step} />}
              {!!indicators && (
                <Indicators
                  indicators={indicators}
                  valueChip={valueChip}
                  itemPositionLeft={(itemValue) =>
                    getValuePercentage(itemValue)
                  }
                  onItemClick={(newValue) => handleChange(null, newValue)}
                />
              )}
            </div>
          </div>
        )}
      />
    );
  },
);
