import { useCurrentRefinements, useRefinementList } from "react-instantsearch";

import {
  algoliaRefinementAttributes,
  algoliaRefinementValueToKey,
  type AlgoliaRefinementAttributesKey,
  type AlgoliaRefinementAttributesValue,
} from "../models/algolia-refinement-attributes";

export const useCurrentRefinementList = (
  refinementKey: AlgoliaRefinementAttributesKey,
) => {
  const { items: currentRefinements } = useCurrentRefinements();
  const { items: availableItems } = useRefinementList({
    attribute: algoliaRefinementAttributes[refinementKey],
  });

  const refinedItems =
    currentRefinements.find(
      (refinement) =>
        algoliaRefinementValueToKey[
          refinement.attribute as AlgoliaRefinementAttributesValue
        ] === refinementKey,
    )?.refinements ?? [];

  const isRefined = refinedItems.length > 0;
  const canRefine = availableItems.length > 0;

  return { refinedItems, availableItems, isRefined, canRefine };
};
