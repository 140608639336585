const highestZIndex = 500;
const step = 10;

// Iterating to generate the zIndex values from highest to lowest
// with a step of 10 between each layer
const getZindexObjectFromIterationKeys = (keys: string[]) =>
  keys.reduce(
    (acc, key, index) => ({ ...acc, [key]: highestZIndex - index * step }),
    {},
  );

// ↪️ If you want to create a new z-[layer] in tailwind you need to add it here
const zIndexLayersKeysHighestToLowest = [
  "popover",
  "dialog",
  "dialog-overlay",
  "popover-under-dialog",
  "topbar",
  "topbar-menu",
  "sidebar",
  "media-slider-current-item",
  "media-slider-item",
];

export const generatedZIndex = getZindexObjectFromIterationKeys(
  zIndexLayersKeysHighestToLowest,
);
