import {
  EquitySavingPlan,
  Organization,
  OrganizationMember,
  OrganizationPerson,
} from "@tudigo-monorepo/core-tudigo-api-models";

import { ContentType, Request, RequestParams, TudigoResponse } from "../api";

export interface PatchOrganizationRequestBody {
  id: Organization["id"];
  name?: Organization["name"];
  companyInformation?: Organization["companyInformation"];
  ultimateBeneficialOwners?: OrganizationPerson[];
  documents?: Organization["documents"];
}

export interface CreateOrganizationRequestBody {
  type: Organization["type"];
  members?: { user: { id: string } }[];
}

export const organizationsEndpoints = (request: Request) => ({
  /**
   * No description
   *
   * @name getOrganizationById
   * @summary get organization by id
   * @request GET:/api/v1/users/me/organizations/:organizationId
   * @secure
   */
  getOrganizationById: (
    organizationId: Organization["id"],
    query?: {
      [key: string]: unknown;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<Organization>, void>({
      path: `/api/v1/users/me/organizations/${organizationId}`,
      method: "GET",
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  /**
   * No description
   *
   * @name getUserOrganizations
   * @summary get organizations
   * @request GET:/api/v1/users/me/organizations
   * @secure
   */
  getUserOrganizations: (
    userId: string | null,
    query?: {
      [key: string]: unknown;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<Organization[]>, void>({
      path: `/api/v1/users/${userId ?? "me"}/organizations`,
      method: "GET",
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  patchOrganization: (
    organizationId: Organization["id"],
    body: PatchOrganizationRequestBody,
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<Organization>, void>({
      path: `/api/v1/organizations/${organizationId}`,
      method: "PATCH",
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  getOrganizations: (
    query?: {
      [key: string]: unknown;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<Organization[]>, void>({
      path: `/api/v1/organizations`,
      method: "GET",
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  /**
   * No description
   *
   * @name getOrganizationMembers
   * @summary get organizations members
   * @request GET:/api/v1/organizations/{organizationId}/members
   * @secure
   */
  getOrganizationMembers: (
    organizationId: Organization["id"],
    query?: {
      [key: string]: unknown;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<OrganizationMember[]>, void>({
      path: `/api/v1/organizations/${organizationId}/members`,
      method: "GET",
      query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  /**
   * No description
   *
   * @name createOrganization
   * @summary create organization
   * @request POST:/api/v1/organizations
   * @secure
   */
  createOrganization: (
    body: CreateOrganizationRequestBody,
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<Organization>, void>({
      path: `/api/v1/organizations`,
      method: "POST",
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  postKycSubmitDataRequest: (
    organizationId: Organization["id"],
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<Organization>, void>({
      path: `/api/v1/organizations/${organizationId}/kyc-submit-data-requests`,
      method: "POST",
      body: {},
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  /**
   * No description
   *
   * @name getOrganizationEquitySavingPlans
   * @summary get organization equity saving plans
   * @request GET:/api/v1/organizations/{organizationId}/equity-saving-plans
   * @secure
   */
  getOrganizationEquitySavingPlans: (
    organizationId: Organization["id"],
    query?: {
      [key: string]: unknown;
    },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<EquitySavingPlan[]>, void>({
      path: `/api/v1/organizations/${organizationId}/equity-saving-plans`,
      method: "GET",
      query: query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  /**
   * No description
   *
   * @name createOrganizationEquitySavingPlan
   * @summary create organization equity saving plan
   * @request POST:/api/v1/organizations/{organizationId}/equity-saving-plans
   * @secure
   */
  createOrganizationEquitySavingPlan: (
    organizationId: Organization["id"],
    body: EquitySavingPlan,
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<EquitySavingPlan>, void>({
      path: `/api/v1/organizations/${organizationId}/equity-saving-plans`,
      method: "POST",
      body: body,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),
});
