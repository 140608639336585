import { type ReactNode } from "react";
import { type To } from "react-router-dom";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { NavbarLink } from "../navbar-link";

const NavbarMenuLinkLegend = (props: {
  children: ReactNode;
  className?: string;
}) => (
  <p
    className={cn(
      "font-roobert text-black-900 md:group-hover:text-primary-rebrand text-pretty text-[15px] font-normal",
      props.className,
    )}
  >
    {props.children}
  </p>
);

const NavbarMenuLink = (props: {
  children: ReactNode;
  to: To;
  className?: string;
  onClick?: () => void;
}) => (
  <li
    onClick={props.onClick}
    className={cn("flex flex-col gap-y-2", props.className)}
  >
    <NavbarLink
      to={props.to}
      className="font-roobert text-black-primary ml-0 flex flex-col items-start gap-y-2 text-pretty pl-0 font-medium"
    >
      {props.children}
    </NavbarLink>
  </li>
);

const NavbarMenuList = (props: { children: ReactNode }) => (
  <ul className="col-span-4 flex flex-col gap-y-6 lg:col-span-3">
    {props.children}
  </ul>
);

const NavbarMenu = (props: { children: ReactNode; className?: string }) => (
  <ul
    className={cn(
      "grid grid-cols-3 items-start gap-6 lg:grid-cols-12 lg:gap-10",
      props.className,
    )}
  >
    {props.children}
  </ul>
);

export { NavbarMenu, NavbarMenuList, NavbarMenuLink, NavbarMenuLinkLegend };
