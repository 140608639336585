import { NavLink, type NavLinkProps } from "react-router-dom";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

export const NavbarLink = (props: NavLinkProps) => {
  return (
    <NavLink
      {...props}
      className={({ isActive }) =>
        cn(
          "w-fit",
          "group flex items-center gap-3",
          "hover:text-primary-rebrand text-black-primary font-roobert rounded-lg border border-transparent transition-all",
          {
            "text-primary-rebrand": props.to !== "" && isActive,
          },
          props.className,
        )
      }
    >
      {props.children}
    </NavLink>
  );
};
