import { Address } from "../addresses";
import { ClubV2 } from "../club-v2/models";
import { Image } from "../images";
import { Lead } from "../leads/models";
import { Organization } from "../organizations";
import { Project } from "../projects";
import { WMACustomer } from "../wma/models";

export enum UserRole {
  ADMIN = "admin",
  INVESTOR = "investor",
  INVESTMENT_OFFICER = "investment_officer",
  PROJECT_HOLDER = "project_holder",
  WMA = "wma",
  CIRCLE_LEADER = "circle_leader",
}

export interface UserIdentityRole {
  id: string;
  resourceId: string;
  resourceType: string;
  role: UserRole;
}

export type UserInvestorProfileStatus = "completed" | "to_complete";

export interface InvestorProfileStatistics {
  totalInvestmentsAmountInCents: number;
  totalInvestmentsCount: number;
  totalSharesInvestmentsAmountInCents: number;
  totalSharesInvestmentsCount: number;
  totalBondsInvestmentsAmountInCents: number;
  totalBondsInvestmentsCount: number;
}

export interface ProjectHolderStatistics {
  totalProjectsCount: number;
  totalCollectedAmountInCents: number;
}

export interface WMAStatistics {
  totalCustomersCount: number;
  totalInvestmentsCount: number;
  totalInvestmentsAmountInCents: number;
}

export enum CommitmentProfile {
  Activist = "activist",
  Committed = "committed",
  ToBeConvinced = "to_be_convince",
}
export enum Strategy {
  Conservative = "conservative",
  Balanced = "balanced",
  Dynamic = "dynamic",
}
export enum PrivilegedSector {
  FoodAndDrinks = "food_and_drinks",
  Goods = "goods",
  Health = "health",
  Services = "services",
  MobilityAndTourism = "mobility_and_tourism",
  EducationCultureAndLeisure = "education_culture_and_leisure",
  TechnologyAndDigital = "technology_and_digital",
  Innovations = "innovations",
  RenewableEnergies = "renewable_energies",
  RealEstate = "real_estate",
}

export interface InvestorProfile {
  qualificationData: {
    [sectionKey: string]: {
      [key: string]: string | number | Array<number | string> | boolean | null;
    };
  };
  financialSummary?: {
    liquidAssets: number | null;
    annualIncome: number | null;
    netAssets: number | null;
    heritage: number | null;
    debt: number | null;
  };
  engagementType?: `${CommitmentProfile}`;
  strategyType?: `${Strategy}`;
  annualInvestmentCapacity?: number | null;
  preferredInvestmentSectors?: `${PrivilegedSector}`[] | null;
  confirmed?: boolean;
  beAdvisedOptIn?: boolean;
  confirmationAt?: string | null;
  expirationAt?: string | null;
  isQualificationExpired?: boolean | null;
  qualificationVersion?: string | null;
  statistics?: InvestorProfileStatistics | null;
}

export interface WMAProfile {
  id: string;
  status: string;
  organization?: Organization;
  statistics?: WMAStatistics | null;
}

export interface ProjectHolderProfile {
  id: string;
  status: string;
  statistics?: ProjectHolderStatistics | null;
  projects?: Project[];
}

export interface InvestmentOfficerProfile {
  id: string;
  status: string;

  //TODO mettre les stats pour le project investment officer uniquement ici
}

export interface UserStatistics {
  organizationsCount: number;
}

export interface UserState {
  hasDoneIntro: boolean;
}

export interface User {
  id: string;
  email: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  job: string;
  employer: string;
  bio: string | null;
  profileImage: Image | null;
  coverImage: Image | null;
  address: Address | null;
  locale: string | null;
  identityRoles?: UserIdentityRole[];
  investorProfile: InvestorProfile | null;
  investmentOfficerProfile: InvestmentOfficerProfile | null;
  projectHolderProfile: ProjectHolderProfile | null;
  wmaProfile: WMAProfile | null;
  isAdmin?: boolean;
  gender?: "male" | "female";
  individualOrganization: Organization | null;
  appRoles: UserRole[];
  statistics?: UserStatistics;
  state?: UserState;
  notificationSettings: {
    newsletterOptIn: boolean;
    notificationChannels: string[];
    signatureVerificationChannel: "sms" | "email";
  };
  followedProjectLeads: Lead[] | null;
  organizationsOwned?: Organization[];
  circlesOwnedAsCircleLeader?: ClubV2[];
  createdAt?: string;
  myCircles?: ClubV2[];
  pendingWmaInvitations?: WMACustomer[];
  unreadNotificationCount?: number;
}
