import * as React from "react";
import {
  ChevronLeft,
  ChevronRight,
  ChevronsLeftIcon,
  ChevronsRightIcon,
  MoreHorizontal,
} from "lucide-react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import {
  buttonRebrandCVA,
  ButtonRebrandProps,
} from "../rebrand/ui/button/button-rebrand";

const PaginationRebrand = ({
  className,
  ...props
}: React.ComponentProps<"nav">) => (
  <nav
    role="navigation"
    aria-label="pagination"
    className={cn("mx-auto flex w-full justify-center", className)}
    {...props}
  />
);

const PaginationRebrandContent = React.forwardRef<
  HTMLUListElement,
  React.ComponentProps<"ul">
>(({ className, ...props }, ref) => (
  <ul
    ref={ref}
    className={cn("flex flex-row items-center gap-1", className)}
    {...props}
  />
));

const PaginationRebrandItem = React.forwardRef<
  HTMLLIElement,
  React.ComponentProps<"li">
>(({ className, ...props }, ref) => (
  <li ref={ref} className={cn("", className)} {...props} />
));

type PaginationRebrandLinkProps = {
  isActive?: boolean;
  disabled?: boolean;
} & Pick<ButtonRebrandProps, "size"> &
  React.ComponentProps<"a">;

const PaginationRebrandLink = ({
  className,
  isActive,
  disabled,
  size = "icon",
  ...props
}: PaginationRebrandLinkProps) => (
  <a
    aria-current={isActive ? "page" : undefined}
    className={cn(
      buttonRebrandCVA({
        variant: "ghost",
        size,
      }),
      "border-black-300 text-black-900 hover:bg-accent-super-light flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border p-0",
      isActive && "text-accent border-accent-light bg-accent-super-light",
      disabled && "bg-black-800 pointer-events-none opacity-50",
      className,
    )}
    {...props}
  />
);

const PaginationRebrandFirst = ({
  className,
  children,
  ...props
}: React.ComponentProps<typeof PaginationRebrandLink>) => (
  <PaginationRebrandLink
    aria-label="Go to first page"
    size="default"
    className={cn(children && "gap-1 pl-2.5", className)}
    {...props}
  >
    <ChevronsLeftIcon className="h-4 w-4" />
    {children && <span>{children}</span>}
  </PaginationRebrandLink>
);

const PaginationRebrandPrevious = ({
  className,
  children,
  ...props
}: React.ComponentProps<typeof PaginationRebrandLink>) => (
  <PaginationRebrandLink
    aria-label="Go to previous page"
    size="default"
    className={cn(children && "gap-1 pl-2.5", className)}
    {...props}
  >
    <ChevronLeft className="h-4 w-4" />
    {children && <span>{children}</span>}
  </PaginationRebrandLink>
);

const PaginationRebrandNext = ({
  className,
  children,
  ...props
}: React.ComponentProps<typeof PaginationRebrandLink>) => (
  <PaginationRebrandLink
    aria-label="Go to next page"
    size="default"
    className={cn(children && "gap-1 pr-2.5", className)}
    {...props}
  >
    {children && <span>{children}</span>}
    <ChevronRight className="h-4 w-4" />
  </PaginationRebrandLink>
);

const PaginationRebrandLast = ({
  className,
  children,
  ...props
}: React.ComponentProps<typeof PaginationRebrandLink>) => (
  <PaginationRebrandLink
    aria-label="Go to last page"
    size="default"
    className={cn(children && "gap-1 pl-2.5", className)}
    {...props}
  >
    {children && <span>{children}</span>}
    <ChevronsRightIcon className="h-4 w-4" />
  </PaginationRebrandLink>
);

const PaginationRebrandEllipsis = ({
  className,
  children,
  ...props
}: React.ComponentProps<"span">) => (
  <span
    aria-hidden
    className={cn("flex h-9 w-9 items-center justify-center", className)}
    {...props}
  >
    <MoreHorizontal className="h-4 w-4" />
    {children && <span className="sr-only">{children}</span>}
  </span>
);

export {
  PaginationRebrand,
  PaginationRebrandContent,
  PaginationRebrandEllipsis,
  PaginationRebrandItem,
  PaginationRebrandLink,
  PaginationRebrandNext,
  PaginationRebrandPrevious,
  PaginationRebrandFirst,
  PaginationRebrandLast,
};
