import {
  getProjectEndDate,
  isLastDaysProject,
  isPrefinishedProject,
  isStartedProject,
  Project,
} from "@tudigo-monorepo/core-tudigo-api-models";
import {
  daysDiffFromNow,
  hoursDiffFromNow,
} from "@tudigo-monorepo/core-tudigo-utils";

const TWO_WEEKS_IN_DAYS = 14;
const ONE_DAY_IN_HOURS = 24;

export function useProjectBusiness(project: Project | undefined) {
  const endDate = getProjectEndDate(project);

  const daysLeft = endDate ? daysDiffFromNow(endDate) : 0;
  const hoursLeft = endDate ? hoursDiffFromNow(endDate) : 0;

  const isLastDays =
    (isStartedProject(project) || isPrefinishedProject(project)) &&
    isLastDaysProject(project) &&
    daysLeft <= TWO_WEEKS_IN_DAYS;

  const isLastHours =
    isLastDays && hoursLeft > 0 && hoursLeft < ONE_DAY_IN_HOURS;

  return {
    endDate,
    daysLeft,
    hoursLeft,
    isLastDays,
    isLastHours,
  };
}
