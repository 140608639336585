import {
  InfiniteData,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import { Notification } from "@tudigo-monorepo/core-tudigo-api-models";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import { getApiClient, getQueryListKey } from "../../..";

export type GetUserNotificationsFilters = {
  userId: string;
  offset?: number;
  limit?: number;
};

export const getUserNotifications = async (
  params: GetUserNotificationsFilters,
) => {
  const apiClient = getApiClient();
  const { userId, offset = 0, limit = 10 } = params;

  const response =
    await apiClient.apiV1.notificationsEndpoints.getNotificationsByUserId(
      userId,
      { offset, limit },
    );

  return response.data;
};

export const useGetUserNotifications = (
  params: GetUserNotificationsFilters,
  options?: UseInfiniteQueryOptions<
    TudigoResponse<Notification[]>,
    TudigoError,
    InfiniteData<TudigoResponse<Notification[]>>,
    TudigoResponse<Notification[]>,
    [string, GetUserNotificationsFilters]
  >,
) => {
  const userNotificationsQueryKey = getQueryListKey({
    key: QUERY_KEYS.NOTIFICATIONS,
    filters: params,
  });

  return useInfiniteQuery<
    TudigoResponse<Notification[]>,
    TudigoError,
    InfiniteData<TudigoResponse<Notification[]>>,
    [string, GetUserNotificationsFilters]
  >({
    queryKey: userNotificationsQueryKey as any,
    queryFn: ({ pageParam = 0 }) => {
      return getUserNotifications({
        ...params,
        offset: Number(pageParam),
      });
    },
    getNextPageParam: (lastPage, allPages) => {
      const offset = allPages.length * (params.limit ?? 10);
      const hasMore = lastPage.data.length === (params.limit ?? 10);

      return hasMore ? offset : undefined;
    },
    initialPageParam: 0,
    enabled: !!params.userId,
    ...options,
  });
};
