import React from "react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import type { InputSliderIndicator } from "./input-slider";

type IndicatorsProps = {
  indicators: InputSliderIndicator[];
  valueChip: boolean;
  itemPositionLeft: (itemValue: number) => number;
  onItemClick: (value: number) => void;
};

export function Indicators(props: IndicatorsProps) {
  const { indicators, valueChip, itemPositionLeft, onItemClick } = props;

  const isFirst = (index: number) => index === 0;
  const isLast = (index: number) => index === indicators.length - 1;

  const getItemPositionLeft = (item: InputSliderIndicator): string => {
    const pos = `${itemPositionLeft(item.value)}%`;

    return `calc(${pos})`;
  };

  return indicators.map((item, index) => (
    <span
      onClick={() => onItemClick(item.value)}
      key={index}
      className={cn(
        "range-slider-indicator",
        "absolute min-w-[15px]",
        "-translate-x-1/2",
        "flex flex-col items-center gap-y-2",
        "sm:cursor-pointer",
        { "top-[20px]": valueChip && !item.icon },
        { "pl-2 sm:pl-0": isFirst(index) },
        { "pr-2 sm:pr-0": isLast(index) },
        { "hidden sm:flex": !isFirst(index) && !isLast(index) },
      )}
      style={{
        left: getItemPositionLeft(item),
      }}
    >
      <span role="img" className="text-base">
        {item.icon}
      </span>

      <span
        className={cn(
          "font-roobert whitespace-nowrap text-xs font-normal leading-[15px]",
          {
            "me-4": index === indicators.length - 1,
          },
        )}
      >
        {item.label}
      </span>
    </span>
  ));
}
