"use client";

import * as React from "react";
import * as TogglePrimitive from "@radix-ui/react-toggle";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

const toggleVariants = cva(
  `
  text-dark-2 font-normal text-sm
  inline-flex  items-center justify-center
  ring-offset-background transition-colors
  hover:bg-muted hover:text-muted-foreground
  focus-keyboard
  disabled:pointer-events-none
  disabled:opacity-50
  data-[state=on]:bg-accent-light-rebrand
  data-[state=on]:text-accent
  `,
  {
    variants: {
      variant: {
        default: "bg-transparent",
        outline: `
          border border-input bg-transparent hover:bg-accent-light-rebrand hover:text-accent-foreground
          last-of-type:rounded-r-md first-of-type:rounded-l-md rounded-none
          border-r-0 last-of-type:border-r
        `,
      },
      size: {
        default: "h-10 px-3",
        sm: "h-9 px-2.5",
        lg: "h-11 px-5",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

const ToggleInput = React.forwardRef<
  React.ElementRef<typeof TogglePrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof TogglePrimitive.Root> &
    VariantProps<typeof toggleVariants>
>(({ className, variant, size, ...props }, ref) => (
  <TogglePrimitive.Root
    ref={ref}
    className={cn(toggleVariants({ variant, size, className }))}
    {...props}
  />
));

export { ToggleInput, toggleVariants };
