import * as React from "react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

export type InputTextareaProps =
  React.TextareaHTMLAttributes<HTMLTextAreaElement>;

const TextareaInput = React.forwardRef<HTMLTextAreaElement, InputTextareaProps>(
  ({ className, ...props }, ref) => {
    return (
      <textarea
        className={cn(
          "focus-keyboard",
          "border-input bg-background ring-offset-background placeholder:text-muted-foreground flex min-h-[80px] w-full rounded-md border px-3 py-2 text-sm disabled:cursor-not-allowed disabled:opacity-50",
          className,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);

export { TextareaInput };
