import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useRepaymentsProjectCapitalDefermentPeriodContext } from "../state/repayments-project-capital-deferment-period.context";

const formSchema = z.object({
  defermentPeriodInMonths: z.number(),
});

export function useRepaymentsProjectCapitalDefermentPeriodForm() {
  const { defaultDefermentPeriodInMonths } =
    useRepaymentsProjectCapitalDefermentPeriodContext();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      defermentPeriodInMonths: defaultDefermentPeriodInMonths,
    },
  });

  return form;
}
