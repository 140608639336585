import { useLocation } from "react-router-dom";

import { getAuthClient } from "@tudigo-monorepo/core-tudigo-api";
import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import { useTranslation } from "@tudigo-monorepo/core-tudigo-translations";

import { Icon } from "../../old/icons/icon";
import { ButtonRebrand } from "../ui/button/button-rebrand";

type LoginOrSignupProps = {
  className?: string;
};

const addRegisterQueryToUrl = (url: string) => {
  const urlObject = new URL(url);
  urlObject.searchParams.append("register", "1");

  return urlObject.toString();
};

export function LoginOrSignupRebrand({ className }: LoginOrSignupProps) {
  const authClient = getAuthClient();
  const authUrl = authClient.authorizeUrl();

  const { pathname } = useLocation();
  const { t } = useTranslation();

  const setProjectTargetUrl = () => {
    if (pathname.includes("/projects/")) {
      localStorage.setItem("target-url", pathname);
    }
  };

  const login = () => {
    setProjectTargetUrl();
    window.location.replace(authUrl);
  };

  const signup = () => {
    setProjectTargetUrl();
    window.location.replace(addRegisterQueryToUrl(authUrl));
  };

  return (
    <div
      className={cn(
        "flex flex-col items-stretch gap-4 md:flex-row-reverse md:!justify-start",
        className,
      )}
    >
      <ButtonRebrand
        size="lg"
        onClick={signup}
        variant="black"
        className="font-roobert"
      >
        {t("signup")}
      </ButtonRebrand>

      <ButtonRebrand
        size="lg"
        onClick={login}
        variant="secondary"
        className="font-roobert gap-x-2 lg:hidden"
      >
        {t("login")}
      </ButtonRebrand>
      <ButtonRebrand
        size="lg"
        onClick={login}
        variant="ghost"
        className="font-roobert hidden gap-x-2 lg:flex"
      >
        <Icon name="Profile" primaryColor="black-900" size="XS" />
        {t("login")}
      </ButtonRebrand>
    </div>
  );
}
