import { PageEnum, useSpaceBusiness } from "@tudigo-monorepo/tudigo-session";

import {
  NavbarMenu,
  NavbarMenuLink,
  NavbarMenuLinkLegend,
  NavbarMenuList,
} from "./navbar-menu";

export const NavbarMenuInvest = (props: {
  className?: string;
  onLinkClick?: () => void;
}) => {
  const { currentPagePaths } = useSpaceBusiness();

  return (
    <NavbarMenu className={props.className}>
      <NavbarMenuList>
        <NavbarMenuLink
          to={currentPagePaths[PageEnum.HOME]}
          onClick={props.onLinkClick}
        >
          Toutes les opportunités
          <NavbarMenuLinkLegend>
            Découvrez les projets en cours de levée sur Tudigo
          </NavbarMenuLinkLegend>
        </NavbarMenuLink>
      </NavbarMenuList>
      <NavbarMenuList>
        <NavbarMenuLink
          to={`${currentPagePaths[PageEnum.HOME]}/financed`.replace("//", "/")}
          onClick={props.onLinkClick}
        >
          Projets financés
          <NavbarMenuLinkLegend>
            Le track record Tudigo : tous les projets financés
          </NavbarMenuLinkLegend>
        </NavbarMenuLink>
      </NavbarMenuList>
      <NavbarMenuList>
        <NavbarMenuLink
          to="https://tudigo.co/pourquoi-investir"
          onClick={props.onLinkClick}
        >
          Pourquoi investir ?
          <NavbarMenuLinkLegend>
            Faites fructifier votre épargne pour un monde meilleur
          </NavbarMenuLinkLegend>
        </NavbarMenuLink>
        <NavbarMenuLink to="https://tudigo.co/faq" onClick={props.onLinkClick}>
          FAQ
          <NavbarMenuLinkLegend>
            Les réponses à toutes vos questions sur Tudigo et le non côté
          </NavbarMenuLinkLegend>
        </NavbarMenuLink>
      </NavbarMenuList>
    </NavbarMenu>
  );
};
