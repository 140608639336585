import {
  createContext,
  useContext,
  useMemo,
  useState,
  type PropsWithChildren,
} from "react";

import type {
  Project,
  RepaymentPeriod,
} from "@tudigo-monorepo/core-tudigo-api-models";

import { getProjectCapitalDefermentPeriods } from "../utils/get-project-capital-deferment-periods";

type RepaymentsProjectCapitalDefermentPeriodContext = {
  canUpdateDefermentPeriod: boolean;
  defaultDefermentPeriodInMonths: number;
  periodIndex: number;
  periodStartDate: string | null;
  project: Project | undefined;
  isDeleting: boolean;
  setIsDeleting: (value: boolean) => void;
};

const Context =
  createContext<RepaymentsProjectCapitalDefermentPeriodContext | null>(null);

function useRepaymentsProjectCapitalDefermentPeriodContext() {
  const context = useContext(Context);

  if (context === null) {
    throw new Error(
      "useRepaymentsProjectCapitalDefermentPeriodContext must be used within a RepaymentsProjectCapitalDefermentPeriodProvider",
    );
  }

  return context;
}

type DefermentPeriodProviderProps = PropsWithChildren<{
  repaymentPeriod: RepaymentPeriod | null;
  project: Project | undefined;
}>;

function RepaymentsProjectCapitalDefermentPeriodProvider({
  children,
  ...props
}: DefermentPeriodProviderProps) {
  const { project, repaymentPeriod } = props;

  const periods = getProjectCapitalDefermentPeriods(project);
  const [isDeleting, setIsDeleting] = useState(false);

  const contextValue = useMemo(() => {
    const periodIndex = repaymentPeriod?.periodIndex ?? -1;
    const periodStartDate = repaymentPeriod?.dueDate ?? null;
    const defaultDefermentPeriodInMonths =
      periods.get(periodIndex.toString()) ?? 0;
    const canUpdateDefermentPeriod = periods.has(periodIndex.toString());

    return {
      defaultDefermentPeriodInMonths,
      canUpdateDefermentPeriod,
      periodIndex,
      periodStartDate,
      project,
      isDeleting,
      setIsDeleting,
    };
  }, [project, repaymentPeriod, periods, isDeleting, setIsDeleting]);

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
}

export {
  type RepaymentsProjectCapitalDefermentPeriodContext,
  RepaymentsProjectCapitalDefermentPeriodProvider,
  useRepaymentsProjectCapitalDefermentPeriodContext,
};
