import { useFormContext } from "react-hook-form";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../components/form";
import { InputConnectedProps } from "../../components/input.type";
import { InputProps, TextInput } from "./text-input";

export function TextInputConnected({
  name,
  label,
  placeholder,
  className,
  description,
  required,
  type = "text",
  ...inputProps
}: InputConnectedProps & InputProps) {
  const form = useFormContext();

  if (!form) {
    throw new Error("InputConnected must be used within a Form component");
  }

  return (
    <FormField
      control={form?.control}
      name={name}
      render={({ field, fieldState }) => {
        return (
          <FormItem className={className}>
            {label && <FormLabel required={required}>{label}</FormLabel>}
            <FormControl>
              <TextInput
                {...field}
                type={type}
                className={cn({ "border-error": Boolean(fieldState?.error) })}
                placeholder={placeholder}
                onChange={(e) => {
                  const value =
                    type === "number" ? Number(e.target.value) : e.target.value;
                  field.onChange(value);
                }}
                {...inputProps}
              />
            </FormControl>

            {fieldState?.error && <FormMessage />}

            {Boolean(!fieldState?.error && description) && (
              <FormDescription>{description}</FormDescription>
            )}
          </FormItem>
        );
      }}
    />
  );
}
