import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  HeadersDefaults,
  ResponseType,
} from "axios";
import applyCaseMiddleware from "axios-case-converter";
import { camelCase } from "change-case";

import { SessionStore, useSessionStore } from "@tudigo-monorepo/tudigo-session";
import useAuthStore, {
  AuthActions,
  AuthState,
} from "@tudigo-monorepo/web-tudigo-auth-store";

import {
  getAuthClient,
  organizationShareholdersEndpoints,
  wmaCustomersEndpoints,
} from "../index";
import { assembliesEndpoints } from "./endpoints/assemblies";
import { banksEndpoints } from "./endpoints/banks";
import { clubsEndPoints } from "./endpoints/clubs";
import { commentsEndPoints } from "./endpoints/comments";
import { configurationEndpoints } from "./endpoints/configuration";
import { consentsEndpoints } from "./endpoints/consents";
import { formsEndpoints } from "./endpoints/forms";
import { investmentDocumentsEndpoints } from "./endpoints/investment-documents";
import { investmentsEndpoints } from "./endpoints/investments";
import { investorDocumentsEndpoints } from "./endpoints/investor-documents";
import { investorProfilesEndpoints } from "./endpoints/investor-profiles";
import { leadsEndpoints } from "./endpoints/leads";
import { notificationsEndpoints } from "./endpoints/notifications";
import { organizationBankAccountsEndpoints } from "./endpoints/organization-bank-accounts";
import { organizationDocumentsEndpoints } from "./endpoints/organization-documents";
import { organizationMembersEndpoints } from "./endpoints/organization-members";
import { organizationPersonDocumentsEndpoints } from "./endpoints/organization-person-documents";
import { organizationPersonsEndpoints } from "./endpoints/organization-persons";
import { organizationsEndpoints } from "./endpoints/organizations";
import { projectHolderProfilesEndpoints } from "./endpoints/project-holder-profiles";
import { projectCategoriesEndpoints } from "./endpoints/projectCategories";
import { projectMaturitiesEndpoints } from "./endpoints/projectMaturities";
import { projectsEndpoints } from "./endpoints/projects";
import { projectTagsEndpoints } from "./endpoints/projectTags";
import { repaymentsEndpoints } from "./endpoints/repayments";
import { reportingsEndpoints } from "./endpoints/reportings";
import { signaturesEndpoints } from "./endpoints/signature";
import { simulatorEndpoints } from "./endpoints/simulator";
import { todosEndpoints } from "./endpoints/todos";
import { userTaxExemptionsEndpoints } from "./endpoints/user-tax-exemptions";
import { userTodosEndpoints } from "./endpoints/user-todos";
import { usersEndpoints } from "./endpoints/users";
import { webflowCollectionsEndpoints } from "./endpoints/webflow-collection";
import { wmaInvestmentsEndpoints } from "./endpoints/wma-investments";
import { wmaProfilesEndpoints } from "./endpoints/wma-profiles";

type BaseTudigoResponse = {
  status: number;
  resourceName: string;
  pagination: {
    count: number;
  };
};

export type TudigoError = {
  httpCode: number;
  code: number;
  statusCode: number;
  message: string;
  parametersErrors: {
    [key: string]: string[];
  };
};

export type TudigoResponse<T> = BaseTudigoResponse & {
  [key: string]: T;
  data: T;
};

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<
  FullRequestParams,
  "body" | "method" | "query" | "path"
>;

export type Request = <T = any, _E = any>({
  secure,
  path,
  type,
  query,
  format,
  body,
  ...params
}: FullRequestParams) => Promise<AxiosResponse<T>>;

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
  baseURL?: string;
  authState?: AuthState;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

let refreshTokenFunction: Promise<any> | undefined;

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;
  private authState?: AuthState & AuthActions;
  private sessionStore: SessionStore;

  constructor({
    securityWorker,
    secure,
    format,
    baseURL,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
    this.authState = useAuthStore.getState();
    this.sessionStore = useSessionStore.getState();

    this.instance = axios.create({
      ...axiosConfig,
      baseURL: baseURL,
    });

    this.instance.interceptors.response.use(
      (response) => {
        if (response.data.resourceName) {
          response.data.data =
            response.data[camelCase(response.data.resourceName)];
        }

        return response;
      },
      async (error) => {
        const originalRequest = error.config;
        if (
          error.response.status === 401 &&
          !originalRequest._retry &&
          this.authState?.refreshToken
        ) {
          originalRequest._retry = true;

          try {
            if (!refreshTokenFunction) {
              refreshTokenFunction = getAuthClient().refreshAccessToken(
                this.authState?.refreshToken,
              );
            }
            const refreshTokenResponse = await refreshTokenFunction;

            if (refreshTokenResponse?.error) {
              throw new Error(refreshTokenResponse.error);
            }

            const { access_token, refresh_token, expires_in } =
              refreshTokenResponse;

            this.authState.setAuth({
              accessToken: access_token,
              refreshToken: refresh_token,
              accessTokenExpirationDate: expires_in,
              impersonateId: this.authState.impersonateId,
            });

            originalRequest.headers.Authorization = `Bearer ${access_token}`;

            return this.instance(originalRequest);
          } catch (error) {
            this.authState.reset();

            return Promise.reject("Refresh token failed.");
          } finally {
            refreshTokenFunction = undefined;
          }
        }

        return Promise.reject(error);
      },
    );

    this.instance = applyCaseMiddleware(this.instance, {
      // preservedKeys: (input) => {
      //   return input.includes(".");
      // },
      caseFunctions: {
        camel: (input) => {
          return input
            .split(".")
            .map((part) =>
              part.replace(/[_-]./g, (match) => match.charAt(1).toUpperCase()),
            )
            .join(".");
        },
      },
    });
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig,
  ): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    const impersonateHeader = this.authState?.impersonateId
      ? { "X-Switch-User": this.authState?.impersonateId }
      : {};

    const userLanguageHeader = this.sessionStore.user?.locale
      ? { "Accept-Language": this.sessionStore.user?.locale }
      : {};

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method &&
          this.instance.defaults.headers[
            method.toLowerCase() as keyof HeadersDefaults
          ]) ||
          {}),
        ...impersonateHeader,
        ...userLanguageHeader,
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
        Authorization: this.authState?.accessToken
          ? `Bearer ${this.authState?.accessToken}`
          : undefined,
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] =
        property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(
          key,
          isFileType ? formItem : this.stringifyFormItem(formItem),
        );
      }

      return formData;
    }, new FormData());
  }

  public request: Request = async ({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }) => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Json && body && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return this.instance
      .request({
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData
            ? { "Content-Type": type }
            : {}),
        },
        params: query,
        responseType: responseFormat,
        data: body,
        url: path,
      })
      .catch((error) => {
        if (error.response.data?.code !== undefined) {
          throw {
            ...error.response.data,
            httpCode: error.response.status,
          } as TudigoError;
        }
        throw {
          httpCode: error.response.status,
          message: error.response.statusText,
        } as TudigoError;
      });
  };
}

/**
 * @title Tudigo API documentation
 * @version 2.0.0
 */
export class TudigoAPI<SecurityDataType> extends HttpClient<SecurityDataType> {
  apiV1 = {
    assemblies: assembliesEndpoints(this.request),
    banks: banksEndpoints(this.request),
    clubs: clubsEndPoints(this.request),
    comments: commentsEndPoints(this.request),
    configuration: configurationEndpoints(this.request),
    consents: consentsEndpoints(this.request),
    forms: formsEndpoints(this.request),
    investments: investmentsEndpoints(this.request),
    investmentDocuments: investmentDocumentsEndpoints(this.request),
    investorProfiles: investorProfilesEndpoints(this.request),
    leadsEndpoints: leadsEndpoints(this.request),
    notificationsEndpoints: notificationsEndpoints(this.request),
    organizationBankAccounts: organizationBankAccountsEndpoints(this.request),
    organizationDocuments: organizationDocumentsEndpoints(this.request),
    organizationPersonDocuments: organizationPersonDocumentsEndpoints(
      this.request,
    ),
    organizationMembers: organizationMembersEndpoints(this.request),
    organizationPersons: organizationPersonsEndpoints(this.request),
    organizations: organizationsEndpoints(this.request),
    projectCategories: projectCategoriesEndpoints(this.request),
    projectMaturities: projectMaturitiesEndpoints(this.request),
    projectTags: projectTagsEndpoints(this.request),
    projects: projectsEndpoints(this.request),
    signatures: signaturesEndpoints(this.request),
    repayments: repaymentsEndpoints(this.request),
    reportings: reportingsEndpoints(this.request),
    userTaxExemptions: userTaxExemptionsEndpoints(this.request),
    investorDocuments: investorDocumentsEndpoints(this.request),
    userTodosEndpoints: userTodosEndpoints(this.request),
    users: usersEndpoints(this.request),
    webflowCollectionsEndpoints: webflowCollectionsEndpoints(this.request),
    wmaCustomers: wmaCustomersEndpoints(this.request),
    wmaProfiles: wmaProfilesEndpoints(this.request),
    wmaInvestments: wmaInvestmentsEndpoints(this.request),
    projectHolderProfiles: projectHolderProfilesEndpoints(this.request),
    simulator: simulatorEndpoints(this.request),
    organizationShareholders: organizationShareholdersEndpoints(this.request),
    todos: todosEndpoints(this.request),
  };
}

export const getResourceFromTudigoResponse = <T>(
  response: TudigoResponse<T>,
) => {
  const resourceName = camelCase(response.resourceName);

  return response[resourceName];
};
