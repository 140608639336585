import React from "react";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@tudigo-monorepo/web-tudigo-components";

import { useNotifications } from "../../../hooks/use-notification";
import { NotificationBell } from "../../bell/notification-bell";
import { NotificationList } from "../../list/notification-list";
import { NotificationMobileDialogNav } from "../mobile/notification-mobile-dialog-nav";

export const NotificationPopoverNavbar = () => {
  const {
    notifications,
    unreadCount,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useNotifications();

  return (
    <>
      <div className="block w-full lg:hidden">
        <NotificationMobileDialogNav />
      </div>

      <div className="hidden lg:block">
        <Popover>
          <PopoverTrigger className="flex items-center">
            <NotificationBell />
          </PopoverTrigger>

          <PopoverContent
            side="bottom"
            align="start"
            className="mt-5 w-[340px] rounded-none p-0 shadow-lg shadow-black/5"
          >
            <div className="flex h-full max-h-[459px] flex-col">
              <div className="flex items-center gap-2 px-8 py-4">
                <h3 className="text-lg font-bold">Notifications</h3>
                {unreadCount > 0 && (
                  <div className="text-waiting bg-secondary-super-light rounded-full px-2.5 py-2 text-xs">
                    {unreadCount}
                  </div>
                )}
              </div>

              <div className="scrollbar-hide flex-1 overflow-y-auto px-8 pb-4">
                {notifications.length > 0 ? (
                  <NotificationList
                    notifications={notifications}
                    hasNextPage={hasNextPage}
                    fetchNextPage={fetchNextPage}
                    isFetchingNextPage={isFetchingNextPage}
                  />
                ) : (
                  <p className="text-dark-2 text-xs">
                    Aucune notification actuellement
                  </p>
                )}
              </div>
            </div>
          </PopoverContent>
        </Popover>
      </div>
    </>
  );
};
