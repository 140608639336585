import { useEffect, useRef } from "react";

import { Notification } from "@tudigo-monorepo/core-tudigo-api-models";

import { NotificationItem } from "./notification-item";

interface NotificationListProps {
  notifications: Notification[];
  hasNextPage?: boolean;
  fetchNextPage?: () => void;
  isFetchingNextPage?: boolean;
}

export function NotificationList({
  notifications,
  hasNextPage,
  fetchNextPage,
  isFetchingNextPage,
}: NotificationListProps) {
  const observerTarget = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage) {
          fetchNextPage?.();
        }
      },
      { threshold: 0.1 },
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => observer.disconnect();
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  if (notifications.length === 0) {
    return (
      <p className="text-black-primary text-center text-sm">
        Aucune notification actuellement
      </p>
    );
  }

  return (
    <div className="flex flex-col gap-2">
      {notifications.map((notif) => (
        <NotificationItem key={notif.id} notification={notif} />
      ))}
      {hasNextPage && (
        <div ref={observerTarget} className="flex justify-center p-4">
          {isFetchingNextPage ? (
            <p className="text-sm text-gray-500">Chargement...</p>
          ) : (
            <p className="text-sm text-gray-500">Faites défiler pour plus</p>
          )}
        </div>
      )}
    </div>
  );
}
