import {
  BondsMechanicSettings,
  BondsProject,
  Project,
  SharesMechanicSettings,
  SharesProject,
} from "./models";

export function isBondsProject(project: Project): project is BondsProject {
  return project.mechanic === "bonds";
}

export function isSharesProject(project: Project): project is SharesProject {
  return project.mechanic === "shares";
}

export function getProjectMechanicSettings(
  project: Project,
): BondsMechanicSettings | SharesMechanicSettings {
  return isBondsProject(project)
    ? project.bondsMechanicSettings
    : project.sharesMechanicSettings;
}

export function getProjectMechanicLabel(project: Project) {
  if (!project) {
    return "";
  }
  if (
    project?.mechanic === "bonds" &&
    project?.bondsMechanicSettings?.isConvertible
  ) {
    return "OCA";
  } else {
    return project?.mechanic;
  }
}

export const isNewProject = (project?: Project) => {
  return (
    project?.marketingSettings?.flag === "new" && project?.status === "started"
  );
};

export const isStartedProject = (project?: Project) => {
  return project?.status === "started";
};

export const isComingProject = (project?: Project) => {
  return project?.status === "scheduled";
};

export const isFinishedProject = (project?: Project) => {
  return project?.status === "finished";
};

export const isPrefinishedProject = (project?: Project) => {
  return project?.status === "prefinished";
};

export const isLastDaysProject = (project?: Project) => {
  return project?.marketingSettings?.flag === "last_days";
};

export const isFailedProject = (project?: Project) => {
  if (!project?.goal || !isFinishedProject(project)) {
    return false;
  }

  return project?.goal.minInCents > project?.totalAmountCollectedInCents;
};

// Attendu : Date de fin du projet
// Comportement "date de clôture effective" si le projet est terminé,
//              sinon la "date de clôture prévue"
// Source : https://tudigo.atlassian.net/browse/TT-1050?focusedCommentId=10302
export function getProjectEndDate(project: Project | undefined) {
  return project?.finishedAt || project?.scheduledEndDate;
}
