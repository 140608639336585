import { useLayoutEffect, useRef } from "react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { ChipCount } from "../../../../old/chip-count";

type ThumbProps = {
  disabled: boolean;
  percentageLeft: number;
  value: number;
  displayChip?: boolean;
  valueChipFormat?: (value: number) => string;
};

export function Thumb(props: ThumbProps) {
  const { disabled, value, percentageLeft, displayChip, valueChipFormat } =
    props;
  const ref = useRef<HTMLDivElement>(null);
  const thumbRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (
      thumbRef.current &&
      ref.current &&
      ref.current?.getBoundingClientRect().left +
        ref.current?.clientWidth -
        window.innerWidth >
        0
    ) {
      thumbRef.current.style.left = `${
        window.innerWidth -
        ref.current?.getBoundingClientRect().left -
        ref.current?.clientWidth
      }px`;
    }
  }, [value]);

  const thumbSize = 16;

  return (
    <div
      ref={ref}
      className={`
        range-slider-thumb
        pointer-events-none absolute
        top-[2px] z-30
        flex flex-col gap-y-1
      `}
      style={{
        left: `calc(${percentageLeft}% - ${thumbSize / 2}px)`,
      }}
    >
      {displayChip && (
        <div ref={thumbRef} className="absolute -top-[30px]">
          <ChipCount
            variant="accent"
            className={cn("whitespace-nowrap rounded-full p-2", {
              "-translate-x-1": value > 0,
            })}
            label={valueChipFormat ? valueChipFormat(value) : value}
          />
        </div>
      )}
      <div
        className={cn(
          "bg-primary-500 rounded-full shadow",
          disabled && "bg-neutral-800",
        )}
        style={{
          width: `${thumbSize}px`,
          height: `${thumbSize}px`,
        }}
      />
    </div>
  );
}
