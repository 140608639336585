import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import {
  getApiClient,
  getQueryDetailKey,
  getQueryListKey,
} from "../../../index";

type RequestParams = {
  notificationId: string;
  userId: string;
};

type MutationOptions = UseMutationOptions<
  TudigoResponse<void>,
  TudigoError,
  RequestParams
>;

export const markNotificationAsRead = async (params: RequestParams) => {
  const apiClient = getApiClient();
  const { notificationId } = params;

  const response =
    await apiClient.apiV1.notificationsEndpoints.markNotificationAsRead({
      notificationId,
    });

  return response.data;
};

export const useMarkNotificationAsReadMutation = (
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (params: RequestParams) => markNotificationAsRead(params),
    onSuccess: (...props) => {
      const [, params] = props;

      queryClient.invalidateQueries({
        queryKey: getQueryListKey({
          key: QUERY_KEYS.NOTIFICATIONS,
        }),
      });

      queryClient.invalidateQueries({
        queryKey: getQueryDetailKey({
          key: QUERY_KEYS.USERS,
          id: params.userId,
        }),
        exact: true,
      });

      options?.onSuccess?.(...props);
    },
    onError: (error, params, context) => {
      options?.onError?.(error, params, context);
    },
  });
};
