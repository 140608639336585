import { cn } from "@tudigo-monorepo/core-tudigo-theme";

type TrackProps = {
  disabled: boolean;
  percentageWidth: number;
};

export function Track(props: TrackProps) {
  const { disabled, percentageWidth } = props;

  return (
    <>
      <div
        className={cn(
          "range-slider-track pointer-events-none absolute left-0 top-2 z-0 h-1 w-full rounded-full bg-neutral-200",
        )}
      />
      <div
        style={{ width: `${percentageWidth}%` }}
        className={cn(
          "range-slider-track-current bg-primary-500 pointer-events-none absolute left-0 top-2 z-0 h-1 rounded-full",
          {
            "bg-neutral-800": disabled,
          },
        )}
      />
    </>
  );
}
