import {
  createContext,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
  type PropsWithChildren,
} from "react";

type MediaSliderContextType = {
  slides: number[];
  thumbs: number[];
  nbSlides: number;
  currentSlide: number;
  setCurrentSlide: (slide: number) => void;
};

const MediaSliderContext = createContext<MediaSliderContextType | null>(null);

function useMediaSlider() {
  const context = useContext(MediaSliderContext);

  if (context === null) {
    throw new Error("useMediaSlider must be used within a MediaSliderProvider");
  }

  return context;
}

function MediaSliderProvider({
  children,
  onSlideChange,
  defaultSlide = 0,
}: PropsWithChildren<{
  defaultSlide?: number;
  onSlideChange?: (newSlide: number) => void;
}>) {
  const [slides, setSlides] = useState<number[]>([]);
  const [currentSlide, setCurrentSlide] = useState(defaultSlide);

  useLayoutEffect(() => {
    const slideElements = document.querySelectorAll("[data-slide-item]");
    const nbElements = slideElements.length;

    setSlides(
      Array.from({ length: nbElements })
        .fill(null)
        .map((_, index) => index),
    );
  }, []);

  const contextValue = useMemo(
    () => ({
      currentSlide,
      slides,
      thumbs: slides.filter((slide) => slide !== currentSlide),
      nbSlides: slides.length,
      setCurrentSlide: (slide: number) => {
        setCurrentSlide(slide);
        onSlideChange?.(slide);
      },
    }),
    [currentSlide, slides, onSlideChange],
  );

  return (
    <MediaSliderContext.Provider value={contextValue}>
      {children}
    </MediaSliderContext.Provider>
  );
}

export { MediaSliderProvider, useMediaSlider, type MediaSliderContextType };
