import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";

import { TudigoError, TudigoResponse } from "@tudigo-monorepo/core-tudigo-api";
import { QUERY_KEYS } from "@tudigo-monorepo/core-tudigo-utils";

import {
  getApiClient,
  getQueryDetailKey,
  getQueryListKey,
} from "../../../index";

export type MarkAllNotificationsAsReadParams = {
  userId: string;
};

type MutationOptions = UseMutationOptions<
  TudigoResponse<void>,
  TudigoError,
  MarkAllNotificationsAsReadParams
>;

export const markAllNotificationsAsRead = async (
  params: MarkAllNotificationsAsReadParams,
) => {
  const apiClient = getApiClient();
  const { userId } = params;

  const response =
    await apiClient.apiV1.notificationsEndpoints.markAllNotificationsAsRead(
      userId,
    );

  return response.data;
};

export const useMarkAllNotificationsAsReadMutation = (
  options?: MutationOptions,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    ...options,
    mutationFn: (params: MarkAllNotificationsAsReadParams) =>
      markAllNotificationsAsRead(params),
    onSuccess: (...props) => {
      const [, params] = props;

      queryClient.invalidateQueries({
        queryKey: getQueryListKey({
          key: QUERY_KEYS.NOTIFICATIONS,
        }),
      });

      queryClient.invalidateQueries({
        queryKey: getQueryDetailKey({
          key: QUERY_KEYS.USERS,
          id: params.userId,
        }),
        exact: true,
      });

      options?.onSuccess?.(...props);
    },
  });
};
