import { Notification } from "@tudigo-monorepo/core-tudigo-api-models";

import {
  ContentType,
  type Request,
  type RequestParams,
  type TudigoResponse,
} from "../api";

export const notificationsEndpoints = (request: Request) => ({
  /**
   * Récupère les notifications d'un utilisateur.
   *
   * @name getNotificationsByUserId
   * @request GET:/api/v1/users/{userId}/notifications
   */
  getNotificationsByUserId: (
    userId: string,
    query?: { [key: string]: unknown },
    params: RequestParams = {},
  ) =>
    request<TudigoResponse<Notification[]>, void>({
      path: `/api/v1/users/${userId}/notifications`,
      method: "GET",
      query,
      secure: true,
      type: ContentType.Json,
      ...params,
    }),

  /**
   * Marque une notification comme lue pour un utilisateur.
   *
   * @name markNotificationAsRead
   * @request PUT:/api/v1/notifications/{notificationId}
   */
  markNotificationAsRead: ({ notificationId }: { notificationId: string }) =>
    request<TudigoResponse<void>, void>({
      path: `/api/v1/notifications/${notificationId}`,
      method: "PUT",
      secure: true,
      type: ContentType.Json,
    }),

  /**
   * Marque toutes les notifications comme lues pour un utilisateur.
   *
   * @name markAllNotificationsAsRead
   * @request PUT:/api/v1/users/{userId}/notifications
   */
  markAllNotificationsAsRead: (userId: string) =>
    request<TudigoResponse<void>, void>({
      path: `/api/v1/users/${userId}/notifications`,
      method: "PUT",
      secure: true,
      type: ContentType.Json,
    }),
});
