import * as React from "react";
import { forwardRef, lazy, Suspense, useMemo } from "react";
import { ChevronsUpDown } from "lucide-react";
import {
  Country,
  FlagProps,
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input";
// @ts-expect-error There is no type definition available for the following import.
import fr from "react-phone-number-input/locale/fr";
import { FixedSizeList as List } from "react-window";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { ButtonRebrand } from "../../../../rebrand/ui/button/button-rebrand";
import {
  Command,
  CommandEmpty,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "../../../command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../popover/popover";

export type CountrySelectProps = {
  disabled?: boolean;
  value: Country | undefined;
  onChange: (value: Country) => void;
  className?: string;
  mini?: boolean;
  showCallingCode?: boolean;
};

type CountryWithLabel = { label: string; value: Country };

const countries = getCountries().map((countryCode) => ({
  label: fr[countryCode],
  value: countryCode,
}));

const CountrySelectInput = ({
  disabled,
  value,
  onChange,
  className,
  showCallingCode = false,
  mini = false,
}: CountrySelectProps) => {
  const [open, setOpen] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");

  const handleSelect = React.useCallback(
    (country: Country) => {
      if (onChange) {
        onChange(country);
      }
      setOpen(false);
    },
    [onChange],
  );

  const CountryCommandItem = React.memo(
    forwardRef<
      HTMLDivElement,
      {
        country: CountryWithLabel;
        isSelected: boolean;
        style?: React.CSSProperties;
      }
    >(({ country, style }, ref) => {
      return (
        <CommandItem
          style={style}
          className="gap-4"
          key={country.value}
          onSelect={() => handleSelect(country.value)}
          ref={ref}
          value={country.label} // Add the value prop here
        >
          <FlagComponent country={country.value} countryName={country.label} />
          <span className="overflow-hidden text-ellipsis text-nowrap text-sm">
            {country.label}
          </span>
          {showCallingCode && (
            <span className="text-foreground/50 ml-auto text-sm">
              {`+${getCountryCallingCode(country.value)}`}
            </span>
          )}
        </CommandItem>
      );
    }),
  );

  const countriesWithoutFrance = useMemo(
    () => countries.filter((x) => x.value !== "FR"),
    [],
  );

  const filteredCountries = useMemo(() => {
    return countriesWithoutFrance.filter((country) =>
      country.label.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }, [searchTerm, countriesWithoutFrance]);

  const itemHeight = 32;

  const listHeight = useMemo(() => {
    return filteredCountries.length > 7
      ? itemHeight * 7
      : filteredCountries.length * itemHeight;
  }, [filteredCountries.length]);

  const handleOpenChange = (isOpen: boolean) => {
    setOpen(isOpen);
    setSearchTerm("");
  };

  return (
    <Popover open={open} onOpenChange={handleOpenChange}>
      <PopoverTrigger asChild>
        <ButtonRebrand
          type="button"
          variant="outline"
          className={cn(
            "flex h-[40px] gap-1 px-3",
            "disabled:bg-light-1",
            !mini && "w-full",
            className,
          )}
          aria-expanded={open}
          role="combobox"
          disabled={disabled}
        >
          <div className="flex items-center gap-2">
            {value && <FlagComponent country={value} countryName={value} />}
            {!mini && <span className="flex-1 text-sm">{fr[value]}</span>}
          </div>
          <ChevronsUpDown
            className={cn(
              "ml-auto h-4 w-4 opacity-50",
              disabled ? "hidden" : "opacity-100",
            )}
          />
        </ButtonRebrand>
      </PopoverTrigger>
      <PopoverContent
        className="min-w-[300px] p-0"
        align="start"
        onCloseAutoFocus={(event) => event.preventDefault()}
      >
        <Command shouldFilter={false}>
          <CommandInput
            placeholder="Rechercher un pays"
            onValueChange={setSearchTerm}
          />
          <CommandList>
            <CommandEmpty>Aucun pays trouvé.</CommandEmpty>
            <CountryCommandItem
              country={{ label: "France", value: "FR" }}
              isSelected={value === "FR"}
            />
            <CommandSeparator />
            <List
              height={listHeight}
              itemCount={filteredCountries.length}
              itemSize={itemHeight}
              width="100%"
              className="!overflow-x-hidden"
            >
              {({ index, style }) => {
                const option = filteredCountries[index];

                return (
                  <CountryCommandItem
                    key={option.value}
                    country={option}
                    isSelected={option.value === value}
                    style={style}
                  />
                );
              }}
            </List>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

const CountrySelectInputPhoneNumber = (props: any) => (
  <CountrySelectInput
    {...props}
    mini
    showCallingCode
    className="rounded-e-none rounded-s-lg border-r-0 "
  />
);

const FlagComponent = ({ country, countryName }: FlagProps) => {
  const Flag = lazy(async () => {
    const flags = await import(`react-phone-number-input/flags`);
    const FlagComponent = (flags.default[country] ||
      (() => null)) as React.ComponentType<{ title: string }>;

    return { default: FlagComponent };
  }) as React.LazyExoticComponent<React.ComponentType<{ title: string }>>;

  return (
    <div className="bg-foreground/20 flex h-3 shrink-0 overflow-hidden rounded-sm">
      <Suspense fallback={<span className="h-[12px] w-[20px] bg-gray-100" />}>
        {Flag && <Flag title={countryName} />}
      </Suspense>
    </div>
  );
};

export { FlagComponent, CountrySelectInput, CountrySelectInputPhoneNumber };
