export const getDate = (date: string | number): Date => {
  if (typeof date === "number") {
    return new Date(date * 1000);
  }

  return new Date(date);
};

export function getDateTimestamp(date: string | number | Date): number {
  const timestamp = new Date(date).getTime();
  const isSeconds = timestamp.toString().length === 10;

  return isSeconds ? timestamp * 1000 : timestamp;
}
