import { BellIcon } from "lucide-react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";
import {
  ButtonRebrand,
  ButtonRebrandProps,
} from "@tudigo-monorepo/web-tudigo-components";

interface NotificationButtonProps {
  variant: ButtonRebrandProps["variant"];
  className?: string;
  unreadNotificationsCount: number;
  isActive?: boolean;
}

export function NotificationButton({
  variant,
  className,
  unreadNotificationsCount,
  isActive,
}: NotificationButtonProps) {
  return (
    <ButtonRebrand
      variant={variant}
      className={cn(
        "font-roobert text-dark-2 group relative flex min-h-[48px] items-center gap-3  text-base font-normal transition-colors",
        className,
        {
          "hover:border-accent-medium hover:text-dark-2 hover:bg-white":
            variant === "ghost",
          "text-primary-rebrand border-accent-medium bg-accent-super-light-rebrand":
            isActive,
        },
      )}
    >
      <BellIcon
        className={cn("h-6 w-6 ", {
          "text-black-900": variant === "ghost",
          "text-dark-2": variant !== "ghost",
          "text-primary-rebrand": isActive,
        })}
      />
      <p>Notifications</p>
      {unreadNotificationsCount > 0 && (
        <div className="bg-secondary-super-light text-waiting font-roobert absolute right-[20px] rounded-full px-2.5 py-2 text-xs">
          {unreadNotificationsCount}
        </div>
      )}
    </ButtonRebrand>
  );
}
