import type { Category } from "./footer-data-rebrand";
import { FooterLink } from "./footer-link";

export function FooterCategoryRebrandDesktop({
  category,
}: {
  category: Category;
}) {
  return (
    <div className="links-bloc flex flex-col gap-y-4 ">
      <p className="font-medium">{category.title}</p>
      <ul className="flex flex-col gap-y-0.5">
        {category.links.map((link, linkIndex) => (
          <li key={linkIndex}>
            <FooterLink
              link={link}
              className="text-black-900 hover:text-primary-rebrand flex items-center gap-2 font-normal transition duration-300 ease-in"
            />
          </li>
        ))}
      </ul>
    </div>
  );
}
