import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { IconName } from "../../../../old/icons/icon-name.type";

type StepMarkersProps = {
  max: number;
  step: number;
  label?: string;
  icon?: IconName | string;
};

const generateStepRanges = (max: number, step: number): number[] => {
  const steps = [];
  for (let i = 0; i <= max; i++) {
    if (i % step === 0) {
      steps.push(i);
    }
  }

  return steps;
};

const stepMarkerSize = 10;

export function StepMarkers(props: StepMarkersProps) {
  const { max, step } = props;
  const stepMarkers = generateStepRanges(max, step);

  return (
    <div
      className={`
      range-slider-step-markers pointer-events-none
      absolute
      w-full
    `}
      style={{
        top: `${stepMarkerSize / 2}px`,
        left: `${stepMarkerSize / 2}px`,
      }}
    >
      {stepMarkers.map((value, index) => (
        <span
          key={value}
          className={cn(
            "absolute",
            "bg-primary-300 h-[10px] w-[10px] rounded-full",
            (index === 0 || index === stepMarkers.length - 1) &&
              "bg-transparent",
          )}
          style={{
            left: `calc(${(value / max) * 100}% - ${stepMarkerSize}px)`,
          }}
        />
      ))}
    </div>
  );
}
