import React from "react";

import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import { NotificationPopoverNavbar } from "@tudigo-monorepo/web-feature-notifications";

import { LoginOrSignupRebrand } from "../login-or-signup-rebrand";
import { UserSpaceLink } from "../user-space-link";

export const NavbarUserBlock = () => {
  const { user } = useUserBusiness();

  return user ? (
    <div className="flex flex-col items-center gap-4 lg:flex-row">
      <NotificationPopoverNavbar />
      <UserSpaceLink className="w-full lg:ml-auto lg:w-fit" />
    </div>
  ) : (
    <LoginOrSignupRebrand className="md:justify-end" />
  );
};
