import { Link } from "react-router-dom";

import { PageEnum, useSpaceBusiness } from "@tudigo-monorepo/tudigo-session";

import type { FooterLinkType } from "./footer-data-rebrand";

export function FooterLink({
  link,
  className,
}: {
  link: FooterLinkType;
  className?: string;
}) {
  const { currentPagePaths } = useSpaceBusiness();

  if (link.href === "/") {
    return (
      <Link to={currentPagePaths[PageEnum.HOME]} className={className}>
        {link.name}
      </Link>
    );
  }

  if (link.href === "/financed") {
    return (
      <Link
        to={`${currentPagePaths[PageEnum.HOME]}/financed`.replace("//", "/")}
        className={className}
      >
        {link.name}
      </Link>
    );
  }

  return (
    <a className={className} href={link.href}>
      {link.name}
    </a>
  );
}
