import { differenceInHours } from "date-fns";

import { getDateTimestamp } from "./date-utils";

export function hoursDiffFromNow(date: string | number | Date): number {
  const timestamp = getDateTimestamp(date);
  const hoursLeft = differenceInHours(timestamp, Date.now());

  return hoursLeft;
}
