import { useMemo } from "react";

import { Notification } from "@tudigo-monorepo/core-tudigo-api-models";
import { useUserBusiness } from "@tudigo-monorepo/tudigo-session";
import {
  useGetUserNotifications,
  useMarkAllNotificationsAsReadMutation,
  useMarkNotificationAsReadMutation,
} from "@tudigo-monorepo/web-tudigo-api-client";

export interface UseNotificationsReturn {
  notifications: Notification[];
  unreadNotifications: Notification[];
  unreadCount: number;
  isError: boolean;
  hasNextPage: boolean;
  fetchNextPage: () => void;
  isFetchingNextPage: boolean;
  markNotificationAsRead: (notificationId: string) => void;
  markAllNotificationsAsRead: () => void;
  isRead: (notification: Notification) => boolean;
}

export function useNotifications(): UseNotificationsReturn {
  const { user } = useUserBusiness();
  const userId = user?.id;

  const { data, isError, hasNextPage, fetchNextPage, isFetchingNextPage } =
    useGetUserNotifications({
      userId: userId!,
      limit: 10,
    });

  const notifications = useMemo(() => {
    return data?.pages.flatMap((page) => page.data) ?? [];
  }, [data]);

  const unreadNotifications = useMemo(
    () => notifications.filter((notification) => !notification.readDate),
    [notifications],
  );

  const { mutate: markNotificationAsRead } =
    useMarkNotificationAsReadMutation();
  const { mutate: markAllNotificationsAsRead } =
    useMarkAllNotificationsAsReadMutation();

  const handleMarkNotificationAsRead = (notificationId: string) => {
    if (!userId) return;
    markNotificationAsRead({ notificationId, userId });
  };

  const handleMarkAllNotificationsAsRead = () => {
    if (!userId) return;
    markAllNotificationsAsRead({ userId });
  };

  const isRead = (notification: Notification): boolean => {
    return notification.readDate !== null;
  };

  return {
    notifications,
    unreadNotifications,
    unreadCount: user?.unreadNotificationCount ?? 0,
    isError,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    markNotificationAsRead: handleMarkNotificationAsRead,
    markAllNotificationsAsRead: handleMarkAllNotificationsAsRead,
    isRead,
  };
}
