import { useRefinementList } from "react-instantsearch";

import {
  algoliaRefinementAttributes,
  type AlgoliaRefinementAttributesKey,
} from "../models/algolia-refinement-attributes";

export function useCanRefineList() {
  const canRefineInstruments = useCanRefineItemsFromKey("instrument");
  const canRefineTaxExemptions = useCanRefineItemsFromKey("tax_exemption");
  const canRefineRisks = useCanRefineItemsFromKey("risk");
  const canRefineCategories = useCanRefineItemsFromKey("category");
  const canRefineMaturities = useCanRefineItemsFromKey("maturity");

  const state: Record<AlgoliaRefinementAttributesKey, boolean> = {
    instrument: canRefineInstruments,
    tax_exemption: canRefineTaxExemptions,
    risk: canRefineRisks,
    category: canRefineCategories,
    maturity: canRefineMaturities,
  };

  return (key: AlgoliaRefinementAttributesKey) => state[key];
}

function useCanRefineItemsFromKey(key: AlgoliaRefinementAttributesKey) {
  const { items } = useRefinementList({
    attribute: algoliaRefinementAttributes[key],
  });

  return items.length > 0;
}
