import React from "react";
import { BellIcon } from "lucide-react";

import { cn } from "@tudigo-monorepo/core-tudigo-theme";

import { useNotifications } from "../../hooks/use-notification";

interface NotificationBellProps {
  isActive?: boolean;
}

export function NotificationBell({ isActive }: NotificationBellProps) {
  const { unreadCount } = useNotifications();

  return (
    <div className="relative flex h-6 w-6 items-center">
      <BellIcon
        className={cn(
          "text-black-900 hover:text-primary-rebrand h-6 w-6 cursor-pointer px-[3px] py-0.5",
          {
            "text-primary-rebrand ": isActive,
          },
        )}
      />
      {unreadCount > 0 && (
        <span className="bg-waiting absolute right-0 top-0 block h-1.5 w-1.5 rounded-full" />
      )}
    </div>
  );
}
